'use clients';
import SVIcon from '@/ui/component/sv-icon';
import { cnm } from '@/utils/cnm';

import useTimer from './useTimer';

export default function Timer() {
  const { formattedTime, start, pause, reset, isPaused, rawSeconds } = useTimer();

  return (
    <div
      className={cnm(
        'w-fit flex gap-2 items-center border-solid border-s p-3 rounded-lg shadow-xs',
        isPaused ? 'bg-bg-white border-border-primary' : 'bg-bg-secondary border-border-white',
        'sticky bottom-5 left-1/2 right-1/2 -translate-x-1/2',
      )}
    >
      {isPaused && rawSeconds > 0 && (
        <button onClick={reset}>
          <SVIcon name="StopCircleLFilled" fill="content-error" />
        </button>
      )}
      <SVIcon name="TimerMOutlined" />
      {formattedTime}
      <button onClick={start}>
        {isPaused && <SVIcon name="PlayCircleLFilled" fill="content-success" />}
      </button>
      {!isPaused && (
        <button onClick={pause}>
          <SVIcon name="PauseCircleLFilled" fill="content-warning" />
        </button>
      )}
    </div>
  );
}
