export const getSessionStorage = <T extends {}>(store: string): T | undefined => {
  const storeValue = sessionStorage.getItem(store);
  if (!storeValue) return;
  return JSON.parse(storeValue);
};

export const setSessionStorage = <T extends {}>(store: string, value: T) => {
  sessionStorage.setItem(store, JSON.stringify(value));
};

export const removeSessionStorage = (store: string) => {
  sessionStorage.removeItem(store);
};
