import { cnm } from '@/utils/cnm';

import { wrapperStyle } from './styles';
import { TagProps } from './types';

export default function Tag({
  children,
  size,
  color,
  iconOnly,
  shape,
  leadingIcon,
  trailingIcon,
  className,
  ...props
}: TagProps) {
  return (
    <div className={cnm(wrapperStyle({ size, color, iconOnly, shape }), className)} {...props}>
      {leadingIcon}
      {children}
      {trailingIcon}
    </div>
  );
}
