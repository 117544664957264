import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface UsePaymentQuestionBankStore {
  selectedCourseList: string[];
  setSelectedCourseList: (courseList: string[]) => void;
}

export const usePaymentQuestionBankStore = create<UsePaymentQuestionBankStore>()(
  devtools(
    (set) => ({
      selectedCourseList: [],
      setSelectedCourseList: (courseList) => set(() => ({ selectedCourseList: courseList })),
    }),
    { name: 'usePaymentQuestionBankStore' },
  ),
);
