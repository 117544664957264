import { usePaymentQuestionBankStore } from '@/store/payment';
import Checkbox from '@/ui/component/checkbox';
import CheckboxGroup from '@/ui/component/checkbox/checkbox-group';
import Tag from '@/ui/component/tag';
import { B1, B2, H5, H5b } from '@/ui/component/typography';
import { cnm } from '@/utils/cnm';

interface PaymentCycleSelectCourseProps {
  name?: string;
  course: string;
  price: number;
  cycle: number;
  sale?: number;
  courseLevel: string[];
}

export default function PaymentCycleSelectCourse({
  course,
  price,
  cycle,
  sale,
  courseLevel,
}: PaymentCycleSelectCourseProps) {
  const { selectedCourseList, setSelectedCourseList } = usePaymentQuestionBankStore();

  return (
    <div
      className={cnm(
        'p-5 bg-bg-white rounded-m outline-solid text-left',
        'group has-[:checked]:!outline has-[:checked]:outline-border-inversePrimary',
      )}
    >
      <div className="flex flex-col gap-2">
        {Boolean(sale) && (
          <div className="flex justify-between">
            <Tag color="orange" size="lg" shape="round">
              SAVE {sale}%
            </Tag>
            <B2 className="text-content-inverseTertiary">
              ${getSalePrice(price, sale, cycle)}per 1Month
            </B2>
          </div>
        )}
        <H5b className="text-content-inversePrimary">{course}</H5b>
      </div>

      <div className="h-6 w-full" />

      <div className="flex gap-2">
        <H5b className="text-content-inversePrimary">$ {getSalePrice(price, sale, cycle)} USD</H5b>
        {Boolean(sale) && (
          <H5 className="text-content-inverseTertiary line-through">$ {price} USD</H5>
        )}
      </div>
      <B1 className="text-content-inverseSecondary">
        Billed each {cycle === 12 ? 'year' : `${cycle}month`}
      </B1>

      <div className="h-4 w-full" />

      <div className="flex flex-col gap-2">
        <B1 className="text-content-inverseSecondary">Select Course</B1>
        <CheckboxGroup
          values={selectedCourseList}
          onChange={setSelectedCourseList}
          name={course}
          className="flex flex-col gap-2"
        >
          {courseLevel?.map((level) => (
            <Checkbox
              value={`${course} ${level}`}
              name={course}
              key={level}
              size="m"
              text={level}
              outline
              className="w-full"
            />
          ))}
        </CheckboxGroup>
      </div>
    </div>
  );
}

// -------------------------------------------------------------------------

// TODO: Sale price calculation
const getSalePrice = (price: number, sale: number = 0, cycle: number = 1) => {
  return Math.floor((price * (1 - sale / 100)) / cycle);
};
