import { cva } from 'class-variance-authority';

export const checkboxStyle = cva(
  [
    '!appearance-none rounded-xs border-solid border-border-inverseSecondary border-s cursor-pointer relative',
    'hover:border-border-primary hover:bg-bg-secondary',
    'checked:border-border-primary checked:bg-bg-secondary',

    // 체크박스 체크 모양 스타일
    'checked:before:block checked:before:rounded-s checked:before:absolute checked:before:bg-bg-primary',
    'checked:after:block checked:after:rounded-s checked:after:absolute checked:after:bg-bg-primary',

    'focus:ring-border-secondary focus:ring-4 focus:border-border-primary focus:bg-bg-secondary',
    'disabled:border-border-inverseTertiary disabled:bg-bg-inverseSecondary disabled:cursor-default',
    'disabled:checked:before:bg-content-inverseTertiary disabled:checked:after:bg-content-inverseTertiary',
  ],
  {
    variants: {
      size: {
        s: [
          'w-4 h-4',
          // 체크모양 S 사이즈
          'checked:before:w-[2px] checked:before:h-[10px] checked:before:rotate-45 checked:before:right-[5px] checked:before:bottom-[2px]',
          'checked:after:w-[2px] checked:after:h-[5px] checked:after:-rotate-45 checked:after:left-[3px] checked:after:bottom-[3px]',
        ],
        m: [
          'w-5 h-5',
          // 체크모양 M 사이즈
          'checked:before:w-[2.5px] checked:before:h-[13px] checked:before:rotate-45 checked:before:right-[6px] checked:before:bottom-[3px]',
          'checked:after:w-[2.5px] checked:after:h-[7px] checked:after:-rotate-45 checked:after:left-[4px] checked:after:bottom-[4px]',
        ],
      },
    },
    defaultVariants: {
      size: 's',
    },
  },
);

export const labelStyle = cva(
  ['flex items-center', 'has-[.radio-text]:gap-2', 'has-[.radio-subText]:items-start w-fit'],
  {
    variants: {
      disabled: {
        true: 'cursor-default',
        false: 'cursor-pointer',
      },
      outline: {
        true: [
          'border-solid border-border-inverseTertiary border-s px-3 py-3 rounded-s',
          'has-[.radio-subText]:py-2 has-[.radio-text]:py-2',
          'has-[:checked]:border-border-primary',
          'has-[:disabled]:border-border-inverseTertiary',
        ],
        false: '',
      },
    },
    defaultVariants: {
      disabled: false,
      outline: false,
    },
  },
);
