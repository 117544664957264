'use client';

import React, { useContext } from 'react';

import { cnm } from '@/utils/cnm';

import CheckboxContext from './checkbox-context';
import { checkboxStyle, labelStyle } from './styles';
import { CheckboxProps } from './types';

export default function Checkbox({
  text,
  subText,
  size = 's',
  outline,
  disabled,
  className,
  ...props
}: CheckboxProps) {
  return (
    <label className={cnm(labelStyle({ disabled, outline }), className)}>
      <CheckBoxInput text={text} disabled={disabled} size={size} {...props} />
      <div>
        {text && (
          <div
            className={cnm(
              'radio-text !text-content-inverseSecondary',
              size === 's' ? 'text-b2b' : 'text-b1b',
            )}
          >
            {text}
          </div>
        )}
        {subText && <div className="radio-subText text-content-inverseTertiary b2">{subText}</div>}
      </div>
    </label>
  );
}

//--------------------CheckboxInput.tsx--------------------

function CheckBoxInput({ disabled, size, value, text, className, ...props }: CheckboxProps) {
  const context = useContext(CheckboxContext);

  if (!context) {
    return (
      <input
        type="checkbox"
        disabled={disabled}
        className={cnm(checkboxStyle({ size }), className)}
        value={value ?? text}
        {...props}
      />
    );
  }

  const { name, isDisabled, isChecked, toggleValue } = context;

  return (
    <input
      type="checkbox"
      name={name}
      disabled={isDisabled((disabled = false))}
      className={cnm(checkboxStyle({ size }), className)}
      checked={isChecked(value ?? text ?? '')}
      onChange={({ target: { checked } }) => toggleValue({ checked, value: value ?? text ?? '' })}
      {...props}
    />
  );
}
