import Image from 'next/image';
import React from 'react';

import Button from '@/ui/component/button';
import SVImage from '@/ui/component/sv-image';
import { B1, H4b } from '@/ui/component/typography';
import { cnm } from '@/utils/cnm';

interface ReviewProps {
  className?: string;
}

const rating = 5;

export default function Review({ className }: ReviewProps) {
  return (
    <div
      className={cnm(
        `w-[400px] shadow-s flex flex-col relative rounded-lg overflow-hidden`,
        className,
      )}
    >
      <div className="relative w-full bg-red-600 flex flex-col items-center justify-start pt-10 pb-[72px] text-center">
        <div className="flex flex-col items-center justify-start z-1 text-content-white">
          <b>{'University of Cambridge'}</b>
          <div>{`Physics & Astronomy`}</div>
        </div>
        <Image
          className="absolute rounded-full object-cover h-[80px] w-[80px] bottom-0 translate-y-1/2 z-20"
          width={80}
          height={80}
          alt="profile-image"
          src="/image/dog.jpeg"
        />
      </div>
      <div className="rounded-b-5 bg-bg-white flex flex-col items-center justify-center p-4 pt-[48px] gap-8">
        <div>
          <H4b>Denis</H4b>
          <div className="flex gap-1 mx-auto">
            {Array.from({ length: rating }).map((_, i) => (
              <SVImage name="star" size="xs" key={i} />
            ))}
          </div>
        </div>

        <B1 className="whitespace-pre-wrap text-left text-content-inverseSecondary">
          {`A year ago, I was struggling with my grades and felt like I had hit a wall.
My tutor was not only incredibly knowledgeable but also patient and dedicated. Over the year, they worked closely with me, identifying my weaknesses and helping me to truly understand the material, rather than just memorizing it.
With their help, I was able to achieve the scores I needed and successfully gained admission to the university I’d been hoping for.`}
        </B1>

        <div className="flex flex-col gap-3 w-full">
          <Button variant="primary" size="m" className="w-full">
            Final Exam Report
          </Button>
          <Button variant="primary" size="m" className="w-full">
            University Admission Letter
          </Button>
        </div>
      </div>
    </div>
  );
}
