import { useCallback, useEffect, useRef, useState } from 'react';

import { getSessionStorage, removeSessionStorage, setSessionStorage } from '@/utils/storage';

type Timer = {
  seconds: number;
  isPaused: boolean;
};

const TIMER = 'timer';

const useTimer = () => {
  const intervalRef = useRef<NodeJS.Timeout | null>();
  const [seconds, setSeconds] = useState(0);
  const [isPaused, setIsPaused] = useState(true);

  const formatTime = useCallback(() => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }, [seconds]);

  const start = useCallback(() => {
    if (intervalRef.current) return;
    setIsPaused(false);
    intervalRef.current = setInterval(() => {
      setSeconds((prev) => {
        setSessionStorage(TIMER, { seconds: prev + 1, isPaused: false });
        return prev + 1;
      });
    }, 1000);
  }, []);

  const pause = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    setIsPaused(true);
    const time = getSessionStorage<Timer>(TIMER);
    if (time) {
      setSessionStorage(TIMER, { seconds: time.seconds, isPaused: true });
    }
  }, []);

  const reset = useCallback(() => {
    pause();
    setSeconds(0);
    removeSessionStorage(TIMER);
  }, [pause]);

  useEffect(() => {
    const timer = getSessionStorage<Timer>(TIMER);
    if (timer) {
      setSeconds(Number(timer.seconds));
      if (!timer.isPaused) {
        setTimeout(start, 0); // Macro task Queue 에 넣어서 순서 보장
      }
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [start]);

  return {
    start,
    pause,
    reset,
    formattedTime: formatTime(),
    isPaused,
    rawSeconds: seconds,
  };
};

export default useTimer;
