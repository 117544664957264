const ImageSize: Record<SVImageSize, number> = {
  xs: 32,
  xlg: 120,
  xxlg: 160,
  xxxlg: 200,
  xxxxlg: 360,
};

const IMAGES_URL = {
  check: '/image/size=xlg, shape=check.png',
  file: '/image/size=xlg, shape=file.png',
  graduateBooks: '/image/size=xlg, shape=graduateBooks.png',
  pencil: '/image/size=xlg, shape=pencil.png',
  plus: '/image/size=xlg, shape=plus.png',
  crown: '/image/size=xs, shape=crown.png',
  star: '/image/size=xs, shape=star.png',
  calculator: '/image/size=xxlg, shape=calculator.png',
  coachTutor: '/image/size=xxxlg, shape=coachTutor.png',
  magnifier: '/image/size=xxxlg, shape=magnifier.png',
  masterTutor: '/image/size=xxxlg, shape=masterTutor.png',
  specialistTutor: '/image/size=xxxlg, shape=specialistTutor.png',
  target: '/image/size=xxxlg, shape=target.png',
  goodnote: '/image/size=xxxxlg, shape=goodnote.png',
};

export type ImageName = keyof typeof IMAGES_URL;

export const getImageSize = (size?: SVImageSize) =>
  ImageSize[size ?? 'xxxlg'] ?? ImageSize['xxxlg'];

export const getImageUrl = ({ name }: { name: ImageName }) => {
  if (!IMAGES_URL[name]) {
    throw new Error('이미지가 존재하지 않습니다.');
  }
  return IMAGES_URL[name];
};
