import * as RAccordion from '@radix-ui/react-accordion';
import React from 'react';

import { cnm } from '@/utils/cnm';

import SVIcon from '../sv-icon';

interface AccordionProps extends RAccordion.AccordionSingleProps {
  list?: { value?: string; title: string; content: string; className?: string }[];
}

export default function Accordion({ type = 'single', defaultValue, list }: AccordionProps) {
  return (
    <RAccordion.Root
      className="w-[720px] rounded-lg bg-bg-white overflow-hidden px-5 text-left"
      type={type}
      defaultValue={defaultValue}
      collapsible
    >
      {list?.map(({ value, title, content, className }) => (
        <AccordionItem key={value ?? title} value={value ?? title} className={className}>
          <AccordionTrigger>{title}</AccordionTrigger>
          <AccordionContent>{content}</AccordionContent>
        </AccordionItem>
      ))}
    </RAccordion.Root>
  );
}
const AccordionItem = ({ children, className, ...props }: RAccordion.AccordionItemProps) => (
  <RAccordion.Item
    className={cnm('overflow-hidden focus-within:relative focus-within:z-accordionItem', className)}
    {...props}
  >
    {children}
  </RAccordion.Item>
);

const AccordionTrigger = ({ children, className, ...props }: RAccordion.AccordionTriggerProps) => (
  <RAccordion.Header className="flex">
    <RAccordion.Trigger
      className={cnm(
        'group text-b1b py-3 flex flex-1 items-center justify-between outline-none whitespace-pre-line text-left',
        className,
      )}
      {...props}
    >
      {children}
      <div className="transition-transform duration-500 ease-in-out rotate-0 group-data-[state=open]:rotate-180">
        <SVIcon name="CaretDownSOutlined" fill="content-inverseTertiary" />
      </div>
    </RAccordion.Trigger>
  </RAccordion.Header>
);

const AccordionContent = ({ children, className, ...props }: RAccordion.AccordionContentProps) => (
  <RAccordion.Content
    className={cnm(
      'overflow-hidden text-b1 text-content-inverseSecondary bg-bg-white data-[state=closed]:animate-slide-up data-[state=open]:animate-slide-down',
      className,
    )}
    {...props}
  >
    {children}
    <div className="h-3 w-ful" id="space" />
  </RAccordion.Content>
);
