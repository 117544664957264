'use client';

import { useEffect, useState } from 'react';

import RadioContext from './radio-context';
import { RadioGroupProps } from './types';

export default function RadioGroup({
  children,
  className,
  name,
  value,
  defaultValue,
  onChange,
}: RadioGroupProps) {
  const [_value, _onChange] = useState(defaultValue);
  const radioValue = value ?? _value;
  const radioOnChange = onChange ?? _onChange;

  useEffect(() => {
    if (defaultValue) {
      onChange?.(defaultValue);
    }
  }, [defaultValue, onChange]);

  return (
    <fieldset className={className}>
      <RadioContext.Provider value={{ name, value: radioValue, onChange: radioOnChange }}>
        {children}
      </RadioContext.Provider>
    </fieldset>
  );
}
