import { cva } from 'class-variance-authority';

export const wrapperStyle = cva(['flex items-center justify-center gap-2'], {
  variants: {
    size: {
      s: 'px-2 py-[2px] !text-lb1b',
      m: 'px-2.5 py-[2px] !text-b2b',
      lg: 'px-3 py-1 !text-b2b',
    },
    color: {
      gray: 'bg-gray-100 text-gray-700',
      red: 'bg-red-100 text-red-700',
      yellow: 'bg-yellow-100 text-yellow-700',
      green: 'bg-green-100 text-green-700',
      blue: 'bg-blue-100 text-blue-700',
      'blue-gray': 'bg-blueGray-100 text-blueGray-700',
      'blue-light': `bg-blueLight-100 text-blueLight-700`,
      indigo: 'bg-indigo-100 text-indigo-700',
      purple: 'bg-purple-100 text-purple-700',
      pink: 'bg-pink-100 text-pink-700',
      rose: 'bg-rose-100 text-rose-700',
      orange: 'bg-orange-100 text-orange-700',
    },
    iconOnly: {
      true: '',
      false: 'w-fit h-fit',
    },
    shape: {
      square: 'rounded-xs',
      round: 'rounded-full',
    },
  },
  compoundVariants: [
    { iconOnly: true, size: 's', className: 'w-5 h-5' },
    { iconOnly: true, size: 'm', className: 'w-6 h-6' },
    { iconOnly: true, size: 'lg', className: 'w-7 h-7' },
  ],
  defaultVariants: {
    size: 's',
    color: 'gray',
    iconOnly: false,
    shape: 'square',
  },
});
