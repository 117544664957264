'use client';

import CheckboxContext from './checkbox-context';
import { CheckboxGroupProps } from './types';

export default function CheckboxGroup({
  children,
  className,
  name,
  disabled: groupDisabled = false,
  values,
  onChange,
}: CheckboxGroupProps) {
  const isDisabled = (disabled: boolean) => disabled || groupDisabled;

  const isChecked = (value: string) => values?.includes(value);

  const toggleValue = ({ checked, value }: { checked: boolean; value: string }) => {
    if (checked) {
      onChange(values?.concat(value));
    } else {
      onChange(values.filter((v) => v !== value));
    }
  };

  return (
    <fieldset className={className}>
      <CheckboxContext.Provider value={{ name, isDisabled, isChecked, toggleValue }}>
        {children}
      </CheckboxContext.Provider>
    </fieldset>
  );
}
